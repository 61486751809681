import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { BaseService } from '../../shared/services/base.service';
import * as FileSaver from 'file-saver';
import { ShowPreviewComponent } from './show-preview/show-preview.component';
import { DomSanitizer } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { IAulaAluno } from '../../shared/models/IAulaAluno';
@Component({
  selector: 'app-justificacao-faltas',
  templateUrl: './justificacao-faltas.component.html',
  styleUrls: ['./justificacao-faltas.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class JustificacaoFaltasComponent implements OnInit {
  @ViewChild('formContainer', { static: false }) formContainer: any;
  aulaAluno: IAulaAluno;
  assiduidadeItems: any;
  podeJustificarFaltas: any;
  isDT: any;
  //#################
  verSumarios: any = false;
  originalData: any;
  existejustificacao: any = false;
  modalRef: BsModalRef;
  flagJustificacaoFaltas: any;
  previewAssiduidade: any;
  private subscription: Subscription[] = [];
  isLoading: any = false;
  
  constructor(public bsModalRef: BsModalRef,
    public baseService: BaseService,
    private modalService: BsModalService,
    private sanitizer: DomSanitizer,
    private spinner: NgxSpinnerService) { }

  ngOnInit() {
    
    if (this.aulaAluno.justificacoesFaltas != null) {
      this.aulaAluno.justificacoesFaltas.flagDT = this.isDT;
      this.existejustificacao = true;
    }
    this.previewAssiduidade = this.aulaAluno.aulaAssiduidade;
    this.originalData = JSON.stringify(this.aulaAluno);
    this.spinner.hide();
  }
  //Caso alterem na dropdown os valores
  atualizaFlags(event: any) {
    if (this.aulaAluno.justificacoesFaltas != null) {
      if (event == 2) {
        this.aulaAluno.justificacoesFaltas.flagRecusado = true;
        this.aulaAluno.justificacoesFaltas.flagAprovado = false;
      } else if (event == 3) {
        this.aulaAluno.justificacoesFaltas.flagAprovado = true;
        this.aulaAluno.justificacoesFaltas.flagRecusado = false;
      }
      this.aulaAluno.justificacoesFaltas.flagFechado = false;
    }
    
  }

  changeAssiduidade() {
    if (this.aulaAluno.justificacoesFaltas != null) {
      if (this.aulaAluno.justificacoesFaltas.flagAprovado || this.aulaAluno.justificacoesFaltas.flagRecusado) {
        if (this.aulaAluno.justificacoesFaltas.flagAprovado) {
          this.aulaAluno.aulaAssiduidade = 3;
        } else {
          this.aulaAluno.aulaAssiduidade = 2;
        }
        this.aulaAluno.justificacoesFaltas.flagFechado = true;
      } else {
        this.aulaAluno.justificacoesFaltas.flagFechado = false;
      }
      if (!this.aulaAluno.justificacoesFaltas.flagAprovado && !this.aulaAluno.justificacoesFaltas.flagRecusado) {
        this.aulaAluno.aulaAssiduidade = this.previewAssiduidade;
      }
    }

    

  }
  
  save() {
    if (this.existejustificacao && this.podeJustificarFaltas) {
      this.changeAssiduidade()
        this.subscription.push(
          this.baseService.put("api/JustificacaoFaltas/justificacao-update/" + this.aulaAluno.justificacoesFaltas.idJustificacao, this.aulaAluno.justificacoesFaltas)
            .subscribe(resp => {
              this.changeAssiduidade()
              this.bsModalRef.hide();
              this.bsModalRef.content.passDataToParent(this.aulaAluno);

            }, (erro) => {
              Swal.fire(
                'Ocorreu um erro!',
                'Ocorreu um erro a guardar a alteração de assiduidade. Por favor tente novamente mais tarde!',
                'error'
              );
              var obs = this.aulaAluno.obsAlunoAula;
              this.aulaAluno = JSON.parse(this.originalData);
              //Em caso de ter alterado apenas as OBSERVAÇÕES
              this.aulaAluno.obsAlunoAula = obs;
              this.bsModalRef.hide();
              this.bsModalRef.content.passDataToParent(this.aulaAluno);
            })
        )
      

    } else {
      this.bsModalRef.hide();
      this.bsModalRef.content.passDataToParent(this.aulaAluno);
    }
      
    
  }

  downloadFile() {
    this.spinner.show();
    this.subscription.push(
      this.baseService.get("api/JustificacaoFaltas/" + this.aulaAluno.justificacoesFaltas.ficheiro.idFicheiro).subscribe((res: any) => {
        const fileContent = res.value.fileContent;
        const extensao = this.aulaAluno.justificacoesFaltas.ficheiro.extensao.replace(".", "");
        const type = extensao == "pdf" ? "application/" : "image/"
        const fileName = 'Justificacao.' + extensao;
        // Convert base64 string to Uint8Array
        const byteArray = this.base64ToUint8Array(fileContent);
        // Create a Blob from the Uint8Array
        const blob = new Blob([byteArray], { type: type });
        FileSaver.saveAs(blob, fileName);
        this.spinner.hide();
      }, (err) => {
        Swal.fire(
          'Erro',
          'De momento não foi possivel fazer download do ficheiro. Tente novamente mais tarde.',
          'error'
        );
        this.spinner.hide();
      })
    )
  }

  previewFile() {
    //this.spinner.show();
    this.subscription.push(
      this.baseService.get("api/JustificacaoFaltas/" + this.aulaAluno.justificacoesFaltas.ficheiro.idFicheiro).subscribe((res: any) => {
        const modalConfig = {
          class: "modal-xl minHeight",
        };
        const extensao = this.aulaAluno.justificacoesFaltas.ficheiro.extensao.replace(".", "");
        const type = extensao == "pdf" ? "application/" : "image/"
        const data = this.sanitizer.bypassSecurityTrustResourceUrl("data:" + type + extensao + ";base64," + res.value.fileContent);

        const initialState = {
          data: data,
          isPdf: extensao=="pdf" ? true : false
        }
        this.modalRef = this.modalService.show(ShowPreviewComponent, Object.assign({}, modalConfig, { initialState }));
        this.spinner.hide();
      }, (err) => {
        Swal.fire(
          'Erro',
          'De momento não foi possivel fazer download do ficheiro. Tente novamente mais tarde.',
          'error'
        );
        this.spinner.hide();
      })
    )
  }

  isFormChanged(): boolean {
    return this.originalData !== JSON.stringify(this.aulaAluno);
  }

  closeModal() {
    this.bsModalRef.hide();
  }

  base64ToUint8Array(base64: string): Uint8Array {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);

    for (let i = 0; i < len; ++i) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    return bytes;
  }
}
