import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { AppComponent } from './app.component';
import { routing } from './app.routing';
import { SharedService } from "./shared/services/shared.service";
import { NotificationsService } from "./shared/services/notifications.service";
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { EmailSenderService } from './shared/services/email-sender.service';
import { ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AlertComponent } from './shared/directives/alert/alert.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { ErrorInterceptor } from './shared/helpers/error.interceptor';
import { JwtInterceptor } from './shared/helpers/jwt.interceptor';
import { AlertService, AuthenticationService } from './shared/services';
import { LoginComponent } from './pages/login/login.component';
import { RecoverPasswordComponent } from './pages/recover/recover-password.component';
import { CanDeactivateGuard } from './shared/guards/can-deactivate.guard';
import { NgxSpinnerModule } from 'ngx-spinner';
import { HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { MyHammerConfig } from './shared/hammer-config';
import { SharedModule } from "./shared/shared.module";
import { ProfessorService } from './shared/services/professor.service';
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { UpdateService } from './shared/services/update.service';
import { RecaptchaModule, RecaptchaFormsModule } from "ng-recaptcha";
import { JustificacaoFaltasComponent } from './pages/justificacao-faltas/justificacao-faltas.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ShowPreviewComponent } from './pages/justificacao-faltas/show-preview/show-preview.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { InstallPromptComponent } from './pages/install-prompt/install-prompt.component';
@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    routing,
    ReactiveFormsModule,
    MatSnackBarModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    SharedModule,
    SweetAlert2Module.forRoot({
      provideSwal: () => import('sweetalert2').then(({ default: swal }) => swal.mixin({
        buttonsStyling: false,
        customClass: {
          //container: 'modal-content',
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn'
        }


      }))
    }),
    NgSelectModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    MatCheckboxModule,
    NgxSpinnerModule.forRoot({ type: 'ball-spin-clockwise' }),
    MatTooltipModule,
    TooltipModule.forRoot(),
    MatDialogModule
  ],
  declarations: [
    AlertComponent,
    AppComponent,
    RecoverPasswordComponent,
    LoginComponent,
    JustificacaoFaltasComponent,
    ShowPreviewComponent,
    InstallPromptComponent
  ],
  providers: [
    AuthGuard,
    CanDeactivateGuard,
    SharedService,
    RecoverPasswordComponent,
    ProfessorService,
    NotificationsService,
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    AlertService,
    AuthenticationService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig,
    },
    EmailSenderService,
    UpdateService
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
