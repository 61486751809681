import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthenticationService, AlertService } from '../services';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((errorReponse: any) => {
        if (errorReponse.status === 400) {
          const error = errorReponse.error.message || errorReponse.statusText;
          return throwError(error);
        }

        else if (errorReponse.status === 401) {
          // auto logout if 401 response returned from api
          this.router.navigate(['./login'], { queryParams: { errorLogout: errorReponse.error } });
          //location.reload(true);
        }
        else if (errorReponse instanceof HttpErrorResponse && errorReponse.status === 403) {
          Swal.fire(
            'Acesso Negado',
            'Não tem permissão de acesso!',
            'error'
          );
          this.router.navigate(['./login'], { queryParams: { errorLogout: errorReponse.error } });
          const error = "Não tem permissão de acesso!";
          return throwError(error);

        }
        else if (errorReponse.status === 429) {
          Swal.fire(
            'Acesso Negado',
            errorReponse.error,
            'error'
          );
          this.router.navigate(['./login'], { queryParams: { errorLogout: errorReponse.error } });
          return throwError(errorReponse.error);

        }
        else if (errorReponse.status == 409) {
          return throwError(errorReponse.status);
        }
        
      }))
  }
}
