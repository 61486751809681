import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

 
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class NotificationsService {

  addSubscriberProfessor(subscription: any) {
    return this.http.post(`${environment.apiBaseUrl}/${environment.sigla}/api/notification/subscribe/`, subscription).pipe(catchError(this.handleError));
  }

  sendNotificationProfessores(titulo: string, mensagem: string, listaIds: any) {
    return this.http.post(`${environment.apiBaseUrl}/${environment.sigla}/api/notification/send/`, { titulo: titulo, mensagem: mensagem, listaIds: listaIds }).pipe(catchError(this.handleError));
  }

  private handleError(error: Response | any) {
    console.error('An error occured ', error);
    return Observable.throw(error);
  }

  constructor(private http: HttpClient) { }

}
