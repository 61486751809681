import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first, tap, switchMap, filter } from 'rxjs/operators';
import { Compiler } from '@angular/core';

import { AlertService, AuthenticationService } from '../../shared/services';
import { EmailSenderService } from '../../shared/services/email-sender.service';
import { DeviceDetectorService } from 'ngx-device-detector';

import { BaseService } from '../../shared/services/base.service';
import { Subscription } from 'rxjs';
import { UserService } from '../../shared/services/user.service';
import { SharedService } from '../../shared/services/shared.service';

@Component({
  templateUrl: 'login.component.html',
  styleUrls: ['login.component.scss']
})

export class LoginComponent implements OnInit, OnDestroy {
  private subscription: Subscription[] = [];
  loginForm: FormGroup;
  recuperarForm: FormGroup;
  submitted = false;
  returnUrl: string;
  recuperar: boolean = false;
  passSent: boolean = false;
  ajuda: boolean = false;
  nomeEscola: string = '';
  private reCaptchaToken: string = "";
  private errorLogout: string = null;
  flagManutencao = false;
  siteKeyCaptcha: string = "6LeemkUqAAAAAIcZeutAt34ekn3L3RwkUa89vskT";

  resolved(captchaResponse: string, res) {
    this.reCaptchaToken = captchaResponse;
  }
 
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private _compiler: Compiler,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    private emailSenderService: EmailSenderService,
    public deviceDetectorService: DeviceDetectorService,
    public baseService: BaseService,
    private userService: UserService,
    private sharedService: SharedService) { }

  ngOnInit() {
    this._compiler.clearCache();

    this.subscription.push(
      this.route.queryParams.pipe(filter(params => params.errorLogout))
        .subscribe(params => {
          this.errorLogout = params.errorLogout;
        })
    );

    this.subscription.push(
      this.baseService.get("api/Escola/v2/nome-escola")
        .subscribe((response) => {
          this.nomeEscola = response[0].nomeEscola;
        }, (err) => {
          this.flagManutencao = true;
          console.error(err)
        })
    );

    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

    this.recuperarForm = this.formBuilder.group({
      codProc: ['', Validators.required],
      email: ['', Validators.required],
      recaptcha: [null, Validators.required]
    });

    // reset login status
    // reset login status
    if (this.userService.hasUser() || this.userService.hasToken()) {
      this.subscription.push(
        this.authenticationService.logout(this.errorLogout)
          .pipe(first())
          .subscribe(data => {
            this.errorLogout = null;
          },
            error => {
              this.alertService.error(error);
            })
      );
    }

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }
  get r() { return this.recuperarForm.controls; }

  onSubmit() {
    
      //this.sharedService.setInstallPrompt();
    

    this.alertService.clear();
    this.submitted = true;
    this.subscription.push(
      this.authenticationService.login(this.f.username.value, this.f.password.value, this.deviceDetectorService.isMobile())
        .pipe(first())
        .subscribe(
          data => {
            this.router.navigate([this.returnUrl]);

            //cache
            this.sharedService.appNotInstalledCache();
          },
          error => {
            this.alertService.error(error);
          })
    );
  }

  goBack() {
    this.loginForm.reset();
    //this.recuperarForm.reset();
    this.recuperar = false;
    this.passSent = false;
    this.ajuda = false;
  }

  email() {
    //api/login/v2/recover-password"
    this.subscription.push(
      this.baseService.post("api/auth/v2/forgot-password", { username: this.r.codProc.value, email: this.r.email.value.toLowerCase(), ReCaptcha: this.reCaptchaToken })
        .pipe(
          switchMap(data => this.emailSenderService.recoverPassword(data))
        ).subscribe(
          (response) => {            
            this.passSent = true;
            this.recuperarForm.reset();
          },
          (err) => {
            
            this.alertService.error("Ocorreu um erro a enviar recuperação de palavra-passe.")
          }
        )        
    );
  }

  ngOnDestroy() {
    if (this.subscription != null) this.subscription.forEach(subscription => subscription.unsubscribe());
  }
}
