import { MatPaginatorIntl } from '@angular/material/paginator';
import { Component } from '@angular/core';

@Component({
  template: ``
})
export class MatPaginatorIntlPt extends MatPaginatorIntl {
  firstPageLabel = 'Primeira página';
  itemsPerPageLabel = 'Items por página';
  lastPageLabel = 'Última página';
  nextPageLabel = 'Próxima Página';
  previousPageLabel = 'Página Anterior';
  
  getRangeLabel = (page: number, pageSize: number, length: number) => {
    if (length == 0 || pageSize == 0) { return `0 de ${length}`; }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;
    return `${startIndex + 1} - ${endIndex} de ${length}`;
  }
}
