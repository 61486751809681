import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';

 
import { Observable } from 'rxjs';

@Injectable()
export class EmailSenderService {

  recuperarPassword(codProc) {
    return this.http.get(`${environment.apiBaseUrl}/${environment.sigla}/api/login/recuperarPassword/` + btoa(codProc)).pipe(catchError(this.handleError));
  }

  rememberPassword(to: string, codProc: string, pass: string) {
    return this.http.post(`${environment.apiBaseUrl}/${environment.sigla}/api/EmailSender/remember`, { to: to, codProc: codProc, password: pass })
      .pipe(map(user => {
        return (user);
      }))
      .pipe(catchError(this.handleError));
  }


  recoverPassword(data) {
   var email = {
      to: data.email,
      codProc: data.username,
      server: `${environment.apiBaseUrl}${environment.sigla}`,
      token: data.token
    }
    return this.http.post(`${environment.apiBaseUrl}/${environment.sigla}/api/EmailSender/redefinir`, email)
      .pipe(map(user => {
        return (user);
      }))
      .pipe(catchError(this.handleError));
  }

  //recoverPassword(to: string, codProc: string, id: string) {
  //  return this.http.post(`${environment.apiBaseUrl}/${environment.sigla}/api/EmailSender/redefinir`, { to: to, codProc: codProc, id: id, server: `${environment.apiBaseUrl}${environment.sigla}` })
  //    .pipe(map(user => {
  //      return (user);
  //    }))
  //    .pipe(catchError(this.handleError));
  //}

  changedPassword(to: string, codProc: string, pass: string) {
    return this.http.post(`${environment.apiBaseUrl}/${environment.sigla}/api/EmailSender/changed`, { to: to, codProc: codProc, password: null })
      .pipe(map(user => {
        return (user);
      }))
      .pipe(catchError(this.handleError));
  }
  
  contactenos(to: string, subject: string, message: string, username: string, name: string, sigla: string) {//
    return this.http.post(`${environment.apiBaseUrl}/${environment.sigla}/api/EmailSender/contactenos`, { to: to, subject: subject, message: message, username: username, name: name, sigla: sigla })
      .pipe(map(user => {
        return (user);
      }))
      .pipe(catchError(this.handleError));
  }

  getToken(mail) {
    return this.http.get(`${environment.apiBaseUrl}/${environment.sigla}/api/login/getToken/` + btoa(mail)).pipe(catchError(this.handleError)); 
  }
  createToken(id) {
    return this.http.get(`${environment.apiBaseUrl}/${environment.sigla}/api/login/createToken/` + btoa(id)).pipe(catchError(this.handleError));
  }
  decodeToken(tk) {

    return this.http.get(`${environment.apiBaseUrl}/${environment.sigla}/api/login/decodeToken/` + tk).pipe(catchError(this.handleError));
  }

  private handleError(error: Response | any) {
    console.error('An error occured ', error);
    return Observable.throw(error);
  }

  constructor(private http: HttpClient) { }

}
