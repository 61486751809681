<swal #areYouSureSwal
      title="Tem a certeza?"
      text="A palavra-passe será alterada definitivamente."
      type="warning"
      [swalOptions]="{ allowOutsideClick: false }"
      [showCancelButton]="true"
      cancelButtonText="Cancelar"
      [focusCancel]="true"
      (confirm)="guardarDados()">
</swal>
<swal #endSwal
      title="Sucesso!"
      text="A palavra-passe foi alterada com sucesso."
      type="success"
      [swalOptions]="{ allowOutsideClick: false }"
      [showCancelButton]="false"
      [focusCancel]="true"
      (confirm)="goToHomePage()">
</swal>
<swal #wrongPass
      title="Erro"
      text="A palavra-passe actual inserida é inválida."
      type="error"
      [swalOptions]="{ allowOutsideClick: false }"
      [showCancelButton]="false"
      [focusCancel]="true">
</swal>
<swal #errorSwal
      title="Oops..."
      text="Ocorreu um erro inesperado."
      type="error"
      [swalOptions]="{ allowOutsideClick: false }"
      [showCancelButton]="false"
      [focusCancel]="true"
      (confirm)="goToHomePage()">
</swal>
<div class="container">
  <div class="card card-container">
    <img id="profile-img" class="profile-img-card" src="./assets/img/logo_blue.svg" />
    <p id="profile-name" class="profile-name-card" style="color: #0094d4;font-weight: 500;">Área Reservada do Professor</p>

    <form [formGroup]="frmSignup" *ngIf="isOK">
      <h5 style="text-align:center;">
        Redefinir palavra-passe
      </h5>
      <br>
      <div class="row">
        <div class="col-xs-12 col-sm-12">
          <div class="form-group">
            <label for="password" class="control-label required">Nova palavra-passe</label>
            <input formControlName="password" type="password" class="form-control" placeholder="" title="Nova palavra-passe" maxlength="20" passwordToggle
                   (change)="verficaAcentosNovaPassword()" [ngClass]="{'is-invalid' : frmSignup.controls['password'].invalid && (frmSignup.controls['password'].dirty || frmSignup.controls['password'].touched) }">
            <label class="invalid-feedback" *ngIf="frmSignup.controls['password'].hasError('required')">
              Campo de preenchimento obrigatório.
            </label>
            <label class="col" *ngIf="!frmSignup.controls['password'].hasError('required') && frmSignup.controls['password'].hasError('minlength')"
                   [ngClass]="frmSignup.controls['password'].hasError('minlength')  ? 'invalid-feedback' : ''">
              <i class="zmdi zmdi-close-circle" *ngIf="frmSignup.controls['password'].hasError('minlength')"></i>
              Deve conter no mínimo 6 caracteres.
            </label>
            <label class="col" *ngIf="!frmSignup.controls['password'].hasError('required') && frmSignup.controls['password'].hasError('hasNumber')"
                   [ngClass]="frmSignup.controls['password'].hasError('hasNumber')  ? 'invalid-feedback' : ''">
              <i class="zmdi zmdi-close-circle" *ngIf="frmSignup.controls['password'].hasError('hasNumber')"></i>
              Deve conter no mínimo 1 número.
            </label>
            <label class="col" *ngIf="!frmSignup.controls['password'].hasError('required') && frmSignup.controls['password'].hasError('hasCapitalCase')"
                   [ngClass]="frmSignup.controls['password'].hasError('hasCapitalCase')  ? 'invalid-feedback' : ''">
              <i class="zmdi zmdi-close-circle" *ngIf="frmSignup.controls['password'].hasError('hasCapitalCase')"></i>
              Deve conter no mínimo 1 caracter maiúsculo.
            </label>
            <label class="col" *ngIf="!frmSignup.controls['password'].hasError('required') && frmSignup.controls['password'].hasError('hasSmallCase')"
                   [ngClass]="frmSignup.controls['password'].hasError('hasSmallCase')  ? 'invalid-feedback' : ''">
              <i class="zmdi zmdi-close-circle" *ngIf="frmSignup.controls['password'].hasError('hasSmallCase')"></i>
              Deve conter no mínimo 1 caracter minúsculo.
            </label>
            <label class="col" *ngIf="!frmSignup.controls['password'].hasError('required') && frmSignup.controls['password'].hasError('hasSpecialCharacters')"
                   [ngClass]="frmSignup.controls['password'].hasError('hasSpecialCharacters') ? 'invalid-feedback' : ''">
              <i class="zmdi zmdi-close-circle" *ngIf="frmSignup.controls['password'].hasError('hasSpecialCharacters')"></i>
              Deve conter no mínimo 1 caracter especial.
            </label>
            <label class="col" *ngIf="!frmSignup.controls['password'].hasError('required') && frmSignup.controls['password'].hasError('haveAccentChars')"
                   [ngClass]="frmSignup.controls['password'].hasError('haveAccentChars')  ? 'invalid-feedback' : ''">
              <i class="zmdi zmdi-close-circle" *ngIf="frmSignup.controls['password'].hasError('haveAccentChars')"></i>
              Não pode conter caracteres acentuados.
            </label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12 col-sm-12">
          <div class="form-group">
            <label for="confirmPassword" class="control-label required"> Confirmar palavra-passe</label>
            <input formControlName="confirmPassword" type="password" class="form-control" placeholder="" title="Confirmar palavra-passe" maxlength="20" passwordToggle
                   [ngClass]="{'is-invalid' : frmSignup.controls['confirmPassword'].invalid && (frmSignup.controls['confirmPassword'].dirty || frmSignup.controls['confirmPassword'].touched) }">
            <label class="invalid-feedback" *ngIf="frmSignup.controls['confirmPassword'].hasError('required')">
              Campo de preenchimento obrigatório.
            </label>
            <label class="invalid-feedback" *ngIf="frmSignup.controls['confirmPassword'].hasError('NoPassswordMatch')">
              As palavras-passe inseridas não são iguais.
            </label>
          </div>
        </div>
      </div>
      <alert style="text-align: center"></alert>
      <div class="form-group">
        <button class="btn btn-primary" type="submit" style="width: 100%; cursor: pointer;" [disabled]="frmSignup.invalid" [swal]="areYouSureSwal" aria-label="finalizar">Guardar Alterações</button>
      </div>

    </form>

    <div *ngIf="!isOK" style="text-align:center;">
      <span style="color:red;">{{mensagem}}</span>
      <br /><br /><a href="" (click)="goToHomePage()" alt="login">Login</a>
    </div>
  </div>
</div>


