import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment } from '../../../environments/environment';
 
import { catchError } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class BaseService {

  private readonly baseUrl = `${environment.apiBaseUrl}/${environment.sigla}/`;

  constructor(private http: HttpClient) { }

  get(url: string) {
    return this.http.get(this.baseUrl + url).pipe(catchError(this.handleError));
  }
  getWithObject<T>(url: string): Observable<T> {
    return this.http.get<T>(this.baseUrl + url).pipe(catchError(this.handleError));
  }
  post(url: string, payload: any) {
    return this.http.post(this.baseUrl + url, payload).pipe(catchError(this.handleError));
  }

  delete(url: string) {
    return this.http.delete(this.baseUrl + url).pipe(catchError(this.handleError));
  }

  put(url: string, payload: any) {
    return this.http.put(this.baseUrl + url, payload).pipe(catchError(this.handleError));
  }
  putWithObject<T>(url: string, payload: any) {
    return this.http.put<T>(this.baseUrl + url, payload).pipe(catchError(this.handleError));
  }

  getWithModel<T>(url: string) : Observable<T> {
    return this.http.get<T>(this.baseUrl + url).pipe(catchError(this.handleError));
  }

  postWithModel<T>(url: string, payload: any): Observable<T> {
    return this.http.post<T>(this.baseUrl + url, payload).pipe(catchError(this.handleError));
  }

  deleteWithModel<T>(url: string): Observable<T> {
    return this.http.delete<T>(this.baseUrl + url).pipe(catchError(this.handleError));
  }

  putWithModel<T>(url: string, payload: any): Observable<T> {
    return this.http.put<T>(this.baseUrl + url, payload).pipe(catchError(this.handleError));
  }

  private handleError(error: Response | any) {
    //alert("entra");
    console.error('An error occured', error);
    return Observable.throw(error);
  }

}
