import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-show-preview',
  templateUrl: './show-preview.component.html',
  styleUrls: ['./show-preview.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ShowPreviewComponent implements OnInit {
  data: any;
  isPdf: any;
  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
  }
  closeModal() {
    this.bsModalRef.hide();
  }
}
