import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map, switchMap } from 'rxjs/operators';
import { throwError as observableThrowError, Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import * as jwt_decode from 'jwt-decode';
import { User } from '../models/user';
import { UserService } from './user.service';

@Injectable()
export class AuthenticationService {

  constructor(
    private http: HttpClient,
    private userService: UserService
  ) { }

  login(username: string, password: string, mobile: boolean) {

    let log: any = [];
    return this.http.post<any>(`${environment.apiBaseUrl}/${environment.sigla}/api/login/v2/authenticate`, { username: username, password: password })
      .pipe(
        map(user => {
        // login successful if there's a jwt token in the response
        if (user && user.token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          sessionStorage.setItem('currentUser', JSON.stringify(user));
          this.userService.setUser(user);
          //userType: 1 = Professores
          //userType: 2 = Alunos
          log = { userType: 1, date: new Date(), isMobile: mobile }
          return log;
        }
        }),
        switchMap(log => {
          let userUid = this.userService.getUserUid();
          return this.http.post(`${environment.apiBaseUrl}/${environment.sigla}/api/login/registarEntrada/` + userUid, log)
        })
      )
      .pipe(catchError(this.handleError));
  }

  logout(errorLogout) {
    // remove user from local storage to log user out and save in BlackList
    return this.http.post<any>(`${environment.apiBaseUrl}/${environment.sigla}/api/Login/v2/logout`, { errorLogout: errorLogout })
      .pipe(
        map(res => {
          sessionStorage.removeItem('currentUser');
          this.userService.resetUser();
          return res;
        })
    ).pipe(catchError(this.handleError));
  }

  registarEntrada(logsAcessosAreaReservada) {
    return this.http.post(`${environment.apiBaseUrl}/${environment.sigla}/api/login/registarEntrada`, logsAcessosAreaReservada).pipe(catchError(this.handleError));
  }

  private handleError(error: Response | any) {
    return observableThrowError(error);
  }
}
