import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { DeviceDetectorService } from 'ngx-device-detector';
import Swal from 'sweetalert2';
import { CustomValidators } from './custom-validators';
import { EmailSenderService } from '../../shared/services/email-sender.service';
import { UserService } from '../../shared/services/user.service';
import { BaseService } from '../../shared/services/base.service';
import { observable, Subscription } from 'rxjs';
import { AlertService } from '../../shared/services';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['recover-password.component.scss']
})

export class RecoverPasswordComponent implements OnInit {
  private subscription: Subscription[] = [];
  private uidUser: string;
  professorRequest: any;
  validateRecover: any;
  public frmSignup: FormGroup;
  isOK: boolean = false;
  mensagem: string = "";

  token: string;
  email: string;
  username: string;

  @ViewChild('errorSwal', { static: false }) private errorSwal: SwalComponent;
  @ViewChild('endSwal', { static: false }) private endSwal: SwalComponent;
  @ViewChild('wrongPass', { static: false }) private wrongPass: SwalComponent;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private fb: FormBuilder,
    private emailSenderService: EmailSenderService,
    public deviceService: DeviceDetectorService,
    private userService: UserService,
    private baseService: BaseService,
    private alertService: AlertService
  ) {
    this.frmSignup = this.createSignupForm();
  }

  createSignupForm(): FormGroup {
    return this.fb.group(
      {
        password: [
          null,
          Validators.compose([
            Validators.required,
            Validators.minLength(6),
            CustomValidators.patternValidator(/\d/, {
              hasNumber: true
            }),
            CustomValidators.patternValidator(/[A-Z]/, {
              hasCapitalCase: true
            }),
            CustomValidators.patternValidator(/[a-z]/, {
              hasSmallCase: true
            }),
            CustomValidators.patternValidator(/[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/, {
              hasSpecialCharacters: true
            })
          ])
        ],
        confirmPassword: [null, Validators.compose([Validators.required])]
      },
      {
        validator: CustomValidators.passwordMatchValidator
      }
    );
  }

  verficaAcentosNovaPassword() {
    var regexp =
      new RegExp(
        /[ÀÁÂÃÄÅĄĀāàáâãäåąßÒÓÔÕÕÖØŐòóôőõöøĎďŽžÈÉÊËĘèéêëęðÇçČčĆćÐÌÍÎÏĪìíîïīÙÚÛÜŰùűúûüĽĹŁľĺłÑŇŃňñńŔŕŠŚŞšśşŤťŸÝÿýŽŻŹžżźđĢĞģğ]/);
    if (regexp.test(this.frmSignup.value.password))
      this.frmSignup.controls['password'].setErrors({ 'haveAccentChars': true });
  }

  ngOnInit() {
   
    this.route.queryParams.subscribe(params => {
      this.token = params['tk'];
      this.email = params['email'];
      this.username = params['username'];
    });

    //verifica se o token enviado no link de recuperação de password é válido
    var check_token = '';

    this.isOK = true;

   
    //this.subscription.push(
    //  this.baseService.get("api/Login/decodeToken/" + this.token)
    //    .subscribe(
    //      (response) => {
    //        this.validateRecover = response;

    //        if (this.validateRecover != null && this.validateRecover.id != "" && this.validateRecover.id != undefined) {
    //          if (this.validateRecover.expiryDateTime != "" && this.validateRecover.expiryDateTime != undefined) {
    //            const date = new Date(0);
    //            let tokenExpDate = date.setUTCSeconds(this.validateRecover.expiryDateTime);
    //            //alert(tokenExpDate.valueOf()  + " - " + new Date().valueOf());
    //            //verifica se o link não expirou (1 dia por defeito)
    //            if (tokenExpDate.valueOf() < new Date().valueOf()) {
    //              this.mensagem = "ATENÇÃO: A data para a alteração da password expirou!"
    //              this.isOK = false;
    //            }
    //            else {
    //              sessionStorage.setItem("token", this.token);

    //              this.baseService.get("api/Professor/v2/recover-password/" + this.validateRecover.id)
    //                .subscribe(
    //                  (response) => {

    //                    this.professor = response;

    //                    const date = new Date(0);
    //                    var dateTk = date.setUTCSeconds(this.validateRecover.createTkDateTime);
    //                    var datePass = Date.parse(this.professor.dataAlteracaoPassWeb);

    //                    //console.log(new Date(this.validateRecover.createTkDateTime * 1000));
    //                    //console.log(new Date(this.professor.dataAlteracaoPassWeb));

    //                    //console.log(dateTk);
    //                    //console.log(datePass);                    

    //                    //verifica se este link já foi usado para aletração da password sgundo a data de alteração registada na BD
    //                    if (this.professor.dataAlteracaoPassWeb != null && dateTk <= datePass) {
    //                      this.isOK = false;
    //                      this.mensagem = "ATENÇÃO: Link Inativo - A password já foi alterada!";
    //                      sessionStorage.removeItem("token");
    //                    }
    //                    else this.isOK = true;
    //                  },
    //                  (err) => {
    //                    sessionStorage.removeItem("token"),
    //                      console.log("err", err)
    //                  }
    //                );
    //            }
    //          }
    //          else {
    //            this.isOK = false;
    //            sessionStorage.removeItem("token");
    //          }
    //        }
    //      },
    //      (err) => console.log(err)
    //    )
    //);
  }    


  guardarDados() {

    this.alertService.clear();

    this.professorRequest = {
      password: this.frmSignup.value.password,
      confirmPassword: this.frmSignup.value.confirmPassword,
      email: this.email,
      username: this.username,
      token: this.token
    };    

    this.subscription.push(
      this.baseService.post("api/auth/reset-password", this.professorRequest)
      //  .pipe(
      //  switchMap(() => this.emailSenderService.changedPassword(this.email, this.username, null))
      //)
        .subscribe((response: Response) => {
          //this.sendEmail();
          this.endSwal.fire();
          //sessionStorage.removeItem("token");
        },
          error => {
            this.alertService.error(error);
          })
    );
  }

  reloadPage() {
    sessionStorage.removeItem("token");
    location.reload();
  }

  goToHomePage() {
    sessionStorage.removeItem("token");
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(['inicio']));
  }

  //sendEmail() {
  //  this.subscription.push(
  //    this.emailSenderService.changedPassword(this.email, this., null)
  //      .subscribe((response: Response) => { }, (err) => console.log({ err }))
  //  );
  //}

  ngOnDestroy() {
    if (this.subscription != null) this.subscription.forEach(subscription => subscription.unsubscribe());
  }
}
