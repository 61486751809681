import { Directive, forwardRef, Attribute } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
  selector: '[validateBiDate]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => BiDateValidator), multi: true }
  ]
})

export class BiDateValidator implements Validator {
  constructor(@Attribute('validateBiDate') public validateBiDate: string) { }

  validate(c: AbstractControl): { [key: string]: any } {
    
    let date = c.value;
    let today = new Date();
    if (date == null || date == '') {
      return null;
    }
    if (today >= date) {
      return { validateBiDate: true };
    }
    else {
      return null;
    }

  }
}
