import { Injectable, ApplicationRef } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { interval, concat, of, merge, Observable, timer } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { first, map, switchMap, timeout, mapTo, catchError } from 'rxjs/operators';
import { fromPromise } from 'rxjs/internal-compatibility';
import { environment } from '../../../environments/environment';

@Injectable()
export class UpdateService {

  promptEvent: any;
  updateAvailable$: any;
  closed$: any;
  updateSubscription;

  constructor(public appRef: ApplicationRef, public swUpdate: SwUpdate, public snackBar: MatSnackBar) {


    if (this.swUpdate.isEnabled) {
      // Allow the app to stabilize first, before starting polling for updates with `interval()`.
      const appIsStable$ = appRef.isStable.pipe(first(isStable => isStable === true));
      const everySixHours$ = interval((6 * 60 * 60));
      //interval(6 * 60 * 60 * 1000);
      const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everySixHours$);

      everySixHoursOnceAppIsStable$.subscribe(() => swUpdate.checkForUpdate().then(() => console.log('À procura de atualizações...')));
    }
  }

  // Called from app.components.ts constructor
  public checkForUpdates(): void {

    if (this.swUpdate.isEnabled) {

      //this.swUpdate.activated.subscribe(event => {
      //  console.log("activated");
      //  console.log(event);
      //  console.log('old version was', event.previous);
      //  console.log('new version is', event.current);
      //});


      this.swUpdate.available.subscribe(event => {

        this.swUpdate.activateUpdate().then(() => window.location.reload());

        //ANTES 10/11/2023 https://app.clickup.com/t/86933gjzf
        //console.log("available");
        //console.log('current version is', event.current);
        //console.log('available version is', event.available);

        //this.promptUser(event);
      });

      //this.swUpdate.activateUpdate();
    }
  }


  private promptUser(e): void {
    if (e.available) {
      let snackBarRef = this.snackBar.open(
        'Está disponível uma nova versão',
        'Atualizar',
        {
          horizontalPosition: 'center',
          verticalPosition: 'top'
        }
      );
      snackBarRef.onAction().subscribe((version) => {
        this.swUpdate.activateUpdate().then(() => {
          localStorage.getItem("version") == null
          window.location.reload();
        })
      });
    }

  }
}


