import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

 
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class ProfessorService {

  //get api

  getAll() {
    return this.http.get(`${environment.apiBaseUrl}/${environment.sigla}/api/Professor/`).pipe(catchError(this.handleError));
  }
  get(id) {
    return this.http.get(`${environment.apiBaseUrl}/${environment.sigla}/api/Professor/` + btoa(id), { reportProgress: true }).pipe(catchError(this.handleError));
  }
  getContactosdoProfessor(id) {
    return this.http.get(`${environment.apiBaseUrl}/${environment.sigla}/api/Professor/getContactosdoProfessor/` + btoa(id)).pipe(catchError(this.handleError));
  }
  getFotodoProfessor(id) {
    return this.http.get(`${environment.apiBaseUrl}/${environment.sigla}/api/Professor/getFotodoProfessor/` + btoa(id)).pipe(catchError(this.handleError));
  }
  getIdentificacãodoProfessor(id) {
    return this.http.get(`${environment.apiBaseUrl}/${environment.sigla}/api/Professor/getIdentificacãodoProfessor/` + btoa(id)).pipe(catchError(this.handleError));
  }
  getMoradadoProfessor(id) {
    return this.http.get(`${environment.apiBaseUrl}/${environment.sigla}/api/Professor/getMoradadoProfessor/` + btoa(id)).pipe(catchError(this.handleError));
  }
  getGenerodoProfessor(id) {
    return this.http.get(`${environment.apiBaseUrl}/${environment.sigla}/api/Professor/getGenerodoProfessor/` + btoa(id)).pipe(catchError(this.handleError));
  }
  getDocIdentdoProfessor(id) {
    return this.http.get(`${environment.apiBaseUrl}/${environment.sigla}/api/Professor/getDocIdentdoProfessor/` + btoa(id)).pipe(catchError(this.handleError));
  }
  getProfessoresActivosAno() {
    return this.http.get(`${environment.apiBaseUrl}/${environment.sigla}/api/Professor/getProfessoresActivosAno`).pipe(catchError(this.handleError));
  }

  //save api

  save(professor) {
    return this.http.post(`${environment.apiBaseUrl}/${environment.sigla}/api/Professor/`, professor).pipe(catchError(this.handleError));
  }
  saveProfessorContactos(professorContactos) {
    return this.http.post(`${environment.apiBaseUrl}/${environment.sigla}/api/ProfessorContactos`, professorContactos).pipe(catchError(this.handleError));
  }
  saveProfessorFoto(professorFoto) {
    return this.http.post(`${environment.apiBaseUrl}/${environment.sigla}/api/ProfessorFoto`, professorFoto).pipe(catchError(this.handleError));
  }
  saveProfessorIdentificacao(professorIdentificacao) {
    return this.http.post(`${environment.apiBaseUrl}/${environment.sigla}/api/ProfessorIdentificacao`, professorIdentificacao).pipe(catchError(this.handleError));
  }
  saveProfessorMorada(professorMorada) {
    return this.http.post(`${environment.apiBaseUrl}/${environment.sigla}/api/ProfessorMorada`, professorMorada).pipe(catchError(this.handleError));
  }

  //update api

  update(id, professor) {
    return this.http.put(`${environment.apiBaseUrl}/${environment.sigla}/api/Professor/` + btoa(id), professor).pipe(catchError(this.handleError));
  }
  updateProfessorFoto(id, professorFoto) {
    return this.http.put(`${environment.apiBaseUrl}/${environment.sigla}/api/ProfessorFoto/` + btoa(id), professorFoto).pipe(catchError(this.handleError));
  }
  updateProfessorContactos(id, professorContactos) {
    return this.http.put(`${environment.apiBaseUrl}/${environment.sigla}/api/ProfessorContactos/` + btoa(id), professorContactos).pipe(catchError(this.handleError));
  }
  updateProfessorIdentificação(id, professorIdentificação) {
    return this.http.put(`${environment.apiBaseUrl}/${environment.sigla}/api/ProfessorIdentificacao/` + btoa(id), professorIdentificação).pipe(catchError(this.handleError));
  }
  updateProfessorMorada(id, professorMorada) {
    return this.http.put(`${environment.apiBaseUrl}/${environment.sigla}/api/ProfessorMorada/` + btoa(id), professorMorada).pipe(catchError(this.handleError));
  }

  //remove api

  remove(id) {
    return this.http.delete(`${environment.apiBaseUrl}/${environment.sigla}/api/Professor/` + btoa(id)).pipe(catchError(this.handleError));
  }

  private handleError(error: Response | any) {
    console.error('An error occured ', error);
    return Observable.throw(error);
  }


  constructor(private http: HttpClient) { }

}
