import { Directive, forwardRef, Attribute, Input  } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
  selector: '[validateBi]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => BiValidator), multi: true }
  ]
})

export class BiValidator implements Validator {
  constructor( @Attribute('validateBi') public validateBi: string) { }

  @Input('validateBi') docIdentificacao: string;

  getNumberFromChar(letter) {
    if (!isNaN(letter)) {
      return Math.floor(letter);
    } else {
      return letter.toUpperCase().charCodeAt(0) - 55;
    }
  }

  validate(c: AbstractControl): { [key: string]: any } {
    let biNumber = c.value;

    if (biNumber == null || biNumber == '')
      return null;

    if (biNumber.includes('_'))
      return { validateBi: true };

    biNumber = biNumber.replace(/\s/g, "");
    
    if (this.docIdentificacao == '1') {
      let comparador, total = 0;
      for (let i = 0; i < 8; ++i) {
        total += Math.floor((biNumber.charAt(i)) as any) * (9 - i);
      }

      if ((total % 11) != 0) comparador = (11 - total % 11) % 10;

      if (Math.floor((biNumber.charAt(8)) as any) != comparador) return { validateBi: true };

      return null;
    }
    else if (this.docIdentificacao == '5') {
      var sum = 0;
      var secondDigit = false;

      for (let i = biNumber.length - 1; i >= 0; i--) {
        var valor = this.getNumberFromChar(biNumber.charAt(i));
        if (secondDigit) {
          valor *= 2;
          if (valor > 9) {
            valor -= 9;
          }
        }

        sum += valor;
        secondDigit = !secondDigit;
      }

      if (sum % 10 == 0)
        return null;
      else
        return { validateBi: true };
    }
  }
}
