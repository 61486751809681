import { Injectable } from '@angular/core';
import { Router } from "@angular/router"
import { BehaviorSubject, Subject } from '../../../../node_modules/rxjs';

@Injectable()
export class SharedService {
  beforeInstallPrompt;
  private btnToInstalledSubject = new BehaviorSubject<boolean>(false);
  btnToInstalled$ = this.btnToInstalledSubject.asObservable();
  //PARA IOS
  private btnToInstalledIOSSubject = new BehaviorSubject<boolean>(false);
  btnToInstalledIOS$ = this.btnToInstalledIOSSubject.asObservable();
  // Sidebar visibility
  sidebarVisible: boolean;
  sidebarVisibilitySubject: Subject<boolean> = new Subject<boolean>();
  installPrompt: any;


  updatebtnInstalledIOSVariable(newValue: boolean) {
    this.btnToInstalledIOSSubject.next(newValue);
  }

  changeAppInstalledCacheIOS(value) {
    if ('caches' in window) {
      caches.open('appInstallIOS')
        .then(function (cache) {
          cache.put('installed', new Response(value.toString()));
        })
        .catch(function (error) {

        })
    }
  }

  getAppInstalledCacheValueIOS() {
    if ('caches' in window) {
      return caches.open('appInstallIOS')
        .then(function (cache) {
          return cache.match('installed');
        })
        .then(function (response) {
          if (response) {
            return response.text();
          } else {
            return "true";
          }
        })
        .then((text) => {
          return text;
        })
        .then((finalValue) => {
          return finalValue;
        });
    }
  }

  appNotInstalledCacheIOS() {
    return new Promise<void>((resolve, reject) => {
      if ('caches' in window) {
        caches.open('appInstallIOS')
          .then(function (cache) {
            return cache.match('installed');
          })
          .then(function (response) {
            if (response) {
              return response.text();
            } else {
              return "true";
            }
          })
          .then((count) => {
            caches.open('appInstallIOS')
              .then((cache) => {
                if (count == "true") {
                  this.updatebtnInstalledIOSVariable(true);
                  cache.put('installed', new Response("true"));
                } else {
                  this.updatebtnInstalledIOSVariable(false);
                  cache.put('installed', new Response("false"));
                }

                resolve(); // Resolve the promise when everything is done
              })
          })
          .catch(function (error) {
            console.log("Error writing to the cache");
            reject(error); // Reject the promise if there's an error
          })
      } else {
        reject("Caches not supported");
      }
    });
  }






  resetAppInstalledCache() {
    if ('caches' in window) {
      caches.open('appInstall')
        .then(function (cache) {
          cache.put('Quantity', new Response('0'));
        })
        .catch(function (error) {

        })
    }
  }
  appNotInstalledCache() {
    if ('caches' in window) {
      caches.open('appInstall')
        .then(function (cache) {
          return cache.match('Quantity');
        })
        .then(function (response) {
          if (response) {
            return response.text()
          } else {
            return "0"
          }

        })
        .then(function (text) {
          if (text != "NaN") {
            return text;
          } else {
            return "0";
          }
        })
        .then(function (count) {
          caches.open('appInstall')
            .then(function (cache) {
              const parsedValue = parseInt(count, 10)
              cache.put('Quantity', new Response((parsedValue + 1).toString()));
            })
        })
        .catch(function (error) {
          console.log("Erro a escrever na cache")
        })
    }
  }

  getAppInstalledCacheValue() {
    if ('caches' in window) {
      return caches.open('appInstall')
        .then(function (cache) {
          return cache.match('Quantity');
        })
        .then(function (response) {
          if (response) {
            return response.text();
          } else {
            return "0";
          }
        })
        .then((text) => {
          if (text != "NaN") {
            return text;
          } else {
            return "0";
          }
        })
        .then((finalValue) => {
          return finalValue;
        });
    }
  }
  updatebtnInstalledVariable(newValue: boolean) {
    this.btnToInstalledSubject.next(newValue);
  }

  toggleSidebarVisibilty() {
    this.sidebarVisible = !this.sidebarVisible;
    this.sidebarVisibilitySubject.next(this.sidebarVisible);
  }

  openSidebarVisibilty() {
    this.sidebarVisible = true;
    this.sidebarVisibilitySubject.next(this.sidebarVisible);
  }

  closeSidebarVisibilty() {
    this.sidebarVisible = false;
    this.sidebarVisibilitySubject.next(this.sidebarVisible);
  }

  // Theming
  maTheme: string;
  maThemeSubject: Subject<string> = new Subject<string>();

  setTheme(color) {
    this.maTheme = color;
    this.maThemeSubject.next(this.maTheme);
  }

  //PWA install banner prompt
  installPromptSubject: Subject<string> = new Subject<string>();
  setBeforeInstallPrompt(e) {
    this.beforeInstallPrompt = e;
  }
  //setInstallPrompt(e) {
  //  this.installPrompt = e;
  //  this.installPromptSubject.next(e);
  //}
  //INSTALAR COM TRIGGER DE BUTÃO
  //prom;
  //setPromp(e) {
  //  this.prom = e
  //}
  //setInstallPrompt() {
    
  //  console.log('setInstallPromp', this.prom)
  //  this.prom.prompt();
  //  this.prom = null;
  //  //this.installPrompt = e;
  //  //  this.installPromptSubject.next(e);
  //}
  setInstallPrompt() {
    if (this.beforeInstallPrompt != undefined) {
      this.beforeInstallPrompt.prompt();
      this.beforeInstallPrompt.userChoice.then((choiceResult) => {
        var outcome = choiceResult.outcome;
        if (outcome === 'dismissed') {
          this.updatebtnInstalledVariable(true);
        } else {
          this.updatebtnInstalledVariable(false);
          this.beforeInstallPrompt = null;
        }
      });
    }
  }

  setInstallPromptNull() {
    this.installPromptSubject = null;
  }
  //setInstallPromptNull() {
  //  this.installPrompt = null;
  //  this.installPromptSubject.next(null);
  //}

  routerLink(page, param) {
    var path = [page];
 
    for (let item of param) {
      path.push(item)
    }

    this.router.navigate(path);
  }

  constructor(private router: Router) {
    // Hidden the sidebar by default
    this.sidebarVisible = false;
    this.maTheme = 'blue-grey';
  }
}
