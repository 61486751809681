import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SharedService } from '../../shared/services/shared.service';

@Component({
  selector: 'app-install-prompt',
  templateUrl: './install-prompt.component.html',
  styleUrls: ['./install-prompt.component.css']
})
export class InstallPromptComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<InstallPromptComponent>,
    private sharedService: SharedService) {
    this.sharedService.appNotInstalledCacheIOS();
  }
  isIOS = false;
  ngOnInit() {
    if (this.data.mobileType == 'ios') {
      this.isIOS = true;
    } else {
      this.isIOS = false;
    }
  }

  install() {
    this.sharedService.beforeInstallPrompt.prompt();
    this.sharedService.beforeInstallPrompt.userChoice.then(function (choiceResult) {
      var outcome = choiceResult.outcome;
      if (outcome === 'dismissed') {
        this.sharedService.beforeInstallPrompt = null;
      } else {

      }

    });

    // Close the dialog
    this.dialogRef.close(true);
  }

  close() {
    // Close the dialog without installing
    this.dialogRef.close(false);
  }

}
