<div class="modal-body" *ngIf="isPdf" >
  <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
  
  <div  style="height:100%;">
    <object [data]="data" type="application/pdf" style="width:100%;height:100%;">
    </object>
    <!--<iframe  [src]="data" style="width:100%;height:500px;"></iframe>-->
  </div>
</div>

<div class="modal-body" *ngIf="!isPdf" style="padding:0;" (click)="closeModal()">
  <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
  <div>
    <img [src]="data" class="img-fluid" alt="preview">
  </div>

</div>


