import { Directive, forwardRef, Attribute } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
  selector: '[validateNif]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => NifValidator), multi: true }
  ]
})

export class NifValidator implements Validator {
  constructor( @Attribute('validateNif') public validateNif: string) { }

  validate(c: AbstractControl): { [key: string]: any } {
    let nif = c.value;

    if (nif == null || nif == '')
      return null;

    if (nif.includes('_'))
      return { validateNif: true };

    nif = nif.replace(/\s/g, "");

    if (!['1', '2', '3', '5', '6', '8'].includes(nif.substr(0, 1)) &&
      !['45', '70', '71', '72', '77', '79', '90', '91', '98', '99'].includes(nif.substr(0, 2)))
      return { validateNif: true };

    let total = nif[0] * 9 + nif[1] * 8 + nif[2] * 7 + nif[3] * 6 + nif[4] * 5 + nif[5] * 4 + nif[6] * 3 + nif[7] * 2;
    let modulo11 = total - Math.floor(total / 11) * 11;
    let comparador: number;

    if (modulo11 == 1 || modulo11 == 0)
      comparador = 0;
    else
      comparador = 11 - modulo11;

    if (nif[8] != comparador)
      return { validateNif: true };

    return null;
  }
}
