import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { EasyPieChartDirective } from "./directives/easy-pie-chart/easy-pie-chart.directive";
import { JqvMapDirective } from "./directives/jqvmap/jqvmap.directive";
import { FlotDirective } from "./directives/flot/flot.directive";
import { SparklineDirective } from "./directives/sparklines/sparkline.directive";
import { DropzoneDirective } from "./directives/dropzone/dropzone.directive";
import { InputFloatDirective } from "./directives/input-float/input-float.directive";
import { NgxPasswordToggleDirective } from "./directives/ngx-password-toggle/directive";
import { NifValidator } from "./directives/validate-bi-nif/validate-nif.directive";
import { BiValidator } from "./directives/validate-bi-nif/validate-bi.directive";
import { MatPaginatorIntlPt } from './components/matPaginatorIntlPtClass';

import { PhonePipe } from "./components/PhonePipe";
import { BiDateValidator } from './directives/validate-bi-nif/validate-bi-date.directive';

@NgModule ({
  declarations: [
    // Directives
    EasyPieChartDirective,
    JqvMapDirective,
    FlotDirective,
    SparklineDirective,
    DropzoneDirective,
    InputFloatDirective,
    NgxPasswordToggleDirective,
    NifValidator,
    PhonePipe,
    BiValidator,
    BiDateValidator,
    MatPaginatorIntlPt
  ],
  imports: [
    CommonModule,
    RouterModule,
    BsDropdownModule.forRoot()
  ],
  exports: [
    // Directives
    EasyPieChartDirective,
    JqvMapDirective,
    FlotDirective,
    SparklineDirective,
    DropzoneDirective,
    InputFloatDirective,
    NgxPasswordToggleDirective,
    NifValidator,
    BiValidator,
    PhonePipe,
    BiDateValidator
  ]
})

export class SharedModule {  }
