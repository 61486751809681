<div style="display:flex;flex-direction:row-reverse;">
  <button class="btn" (click)="close()" style="margin-top: 0; color: black; background: transparent; border-color: transparent; box-shadow: none;">
    <i class="zmdi zmdi-close zmdi-hc-2x"></i>
  </button>
</div>
<!-- ANDROID -->
<div style="padding: 0px 24px 24px 24px;" *ngIf="!isIOS">
  <div style="text-align:center;">
    <h3>MUSa Software</h3>
    <p>
      Instale agora a aplicação no seu dispositivo.
      <br />
      Usufrua de todas as nossas funcionalidades.
    </p>

    <button class="btn btn-primary" color="primary" (click)="install()"><i class="zmdi zmdi-download"></i>&nbsp;&nbsp; Instale!</button>
  </div>
</div>
<!-- IOS -->
<div style="padding: 0px 24px 24px 24px;" *ngIf="isIOS">
  <div style="text-align:center;">
    <h3>MUSa Software</h3>
    <p>
      Instale agora a aplicação no seu dispositivo.
      <br />
      Usufrua de todas as nossas funcionalidades.
    </p>
    <h5>
      <i class="zmdi zmdi-download"></i>&nbsp; Como instalar em IOS?
    </h5>
    <div style="text-align:left;">
      <p>
        1. Selecione a partilha &nbsp;<img src="assets/icons/safariSharedIcon/icons8-share-32.png" style="max-width:24px; max-height:100%;">
        <br />
        2. Selecione "Adicionar ao ecrã inicial"
      </p>
    </div>

  </div>
</div>

