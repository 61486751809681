import { Directive, ElementRef, Renderer2 } from "@angular/core";


@Directive ({
    selector: '[formControlFloat]',
    host: {
        '(blur)': 'onBlur()'
    }
})

export class InputFloatDirective {
    constructor (private el: ElementRef, private renderer: Renderer2) { }
    elem:any = this.el.nativeElement;

    onBlur() {
        let status = true ? this.elem.value : false;
      this.renderer.addClass(this.elem, 'form-control--active')
    }

    ngOnInit() {
        if(this.elem.value) {
          this.renderer.addClass(this.elem, 'form-control--active')
        }
    }
}
