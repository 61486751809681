
<!--<ng-template>-->
<div class="modal-header">
  <h4 class="modal-title pull-left">Verificar Falta</h4>
  <!--<button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>-->
</div>
<div class="modal-body" #formContainer>
  <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="medium" color="#fff" type="ball-spin-clockwise" fullscreen="true" style="background: red;z-index:1001;"></ngx-spinner>

  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8">
      <div class="form-group fg-line">
        <label class="control-label">Nome do Aluno</label>
        <input type="text" name="nomeAluno" class="form-control" [ngModel]="aulaAluno?.nomeAluno" (ngModelChange)="aulaAluno.nomeAluno = $event" placeholder="" [readonly]="true" />
      </div>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
      <div class="form-group fg-line">
        <label class="control-label">Data da Aula</label>
        <input type="text" name="dataAula" class="form-control" [ngModel]="aulaAluno?.dataAula" (ngModelChange)="aulaAluno.dataAula = $event" placeholder="" [readonly]="true" />
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8">
      <div class="form-group fg-line">
        <label class="control-label">Disciplina (Classe)</label>
        <input type="text" name="disciplina" class="form-control" [ngModel]="aulaAluno?.disciplina" (ngModelChange)="aulaAluno.disciplina = $event" rows="1" placeholder="" [readonly]="true" />
      </div>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
      <div class="form-group fg-line">
        <label class="control-label">Assiduidade</label>
        <ng-select name="aulaAssiduidade" [items]="assiduidadeItems" bindLabel="name" bindValue="id" [ngModel]="aulaAluno?.aulaAssiduidade" (ngModelChange)="aulaAluno.aulaAssiduidade = $event; atualizaFlags($event)"
                   notFoundText="Sem resultados" [clearable]="false" [disabled]="!podeJustificarFaltas" [tooltip]="podeJustificarFaltas ? '' : 'Não tem permissão para alterar as faltas.'">
        </ng-select>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="verSumarios">
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
      <div class="form-group fg-line">
        <label class="control-label">Sumário da Aula</label>
        <textarea type="text" name="aulaSumario" class="form-control" [ngModel]="aulaAluno?.aulaSumario" (ngModelChange)="aulaAluno.aulaSumario = $event" rows="1" placeholder="" [readonly]="true"></textarea>
      </div>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
      <div class="form-group fg-line">
        <label class="control-label">Sumário do Aluno</label>
        <textarea type="text" name="sumarioAluno" class="form-control" [ngModel]="aulaAluno?.sumarioAluno" (ngModelChange)="aulaAluno.sumarioAluno = $event" rows="1" placeholder="" [readonly]="true"></textarea>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <div class="form-group fg-line">
        <label class="control-label">Observações</label>
        <input type="text" name="obsAlunoAula" class="form-control" [ngModel]="aulaAluno?.obsAlunoAula" (ngModelChange)="aulaAluno.obsAlunoAula = $event" placeholder="Escreva alguma coisa..." />
      </div>
    </div>
  </div>
  <!--Se não existir Justificacao-->
  <div class="row" *ngIf="aulaAluno?.justificacoesFaltas == null && flagJustificacaoFaltas || aulaAluno?.justificacoesFaltas?.motivo == null">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <div _ngcontent-c14="" class="alert alert-warning" role="alert">
        Não existe submissão de justificação.
      </div>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" mat-dialog-content *ngIf="aulaAluno?.justificacoesFaltas?.dataAprovado != null || aulaAluno?.justificacoesFaltas?.dataRecusado != null" style="text-decoration: underline;font-size:10px;">
      <div class="row">
        <div class="col-4">
          <strong>Data de Verificação:</strong>
          <span style="margin-left:5px;" *ngIf="aulaAluno.justificacoesFaltas.flagAprovado">{{aulaAluno.justificacoesFaltas.dataAprovado | date: 'dd/MM/yyyy HH:mm'}}</span>
          <span style="margin-left:5px;" *ngIf="aulaAluno.justificacoesFaltas.flagRecusado">{{aulaAluno.justificacoesFaltas.dataRecusado | date: 'dd/MM/yyyy HH:mm'}}</span>
        </div>
        <div class="col-4" *ngIf="aulaAluno.justificacoesFaltas.flagFechado">
          <strong>Verificado por:</strong>
          <span *ngIf="aulaAluno.justificacoesFaltas.idProfessor == 0 || aulaAluno.justificacoesFaltas.idProfessor == null" style="margin-left:5px;">Secretaria</span>
          <span *ngIf="aulaAluno.justificacoesFaltas.flagDT && aulaAluno.justificacoesFaltas.idProfessor != null" style="margin-left:5px;">Diretor</span>
          <span *ngIf="!aulaAluno.justificacoesFaltas.flagDT && aulaAluno.justificacoesFaltas.idProfessor != null" style="margin-left:5px;">Professor</span>
        </div>
      </div>

    </div>
  </div>
  <!-- Justificação -->
  <div *ngIf="aulaAluno?.justificacoesFaltas != null && podeJustificarFaltas && flagJustificacaoFaltas && aulaAluno?.justificacoesFaltas?.motivo != null">
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <h5>Justificação</h5>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <label class="control-label">Data de Submissão:</label>
        <span style="margin-left:5px;" *ngIf="aulaAluno?.justificacoesFaltas?.dataJustificacao != null">{{aulaAluno?.justificacoesFaltas?.dataJustificacao | date: 'dd/MM/yyyy HH:mm'}}</span>
        <span style="margin-left:5px;" *ngIf="aulaAluno?.justificacoesFaltas?.dataJustificacao == null">Não existe submissão de justificação.</span>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6" *ngIf="aulaAluno?.justificacoesFaltas?.ficheiro != null">
        <div class="row">
          <div class="col-6">
            <label class="control-label">Ficheiro:</label>
            <span style="margin-left:5px;">Justificação{{aulaAluno?.justificacoesFaltas?.ficheiro.extensao}}</span>
          </div>
          <div class="col-6" style="justify-content: left; display: flex; align-items: center;">
            <a><i class="zmdi zmdi-eye zmdi-hc-2x" style="color: #ff9800;" (click)="previewFile()"></i></a>
            <a><i class="zmdi zmdi-download zmdi-hc-2x" style="color: #ff9800; margin-left: 10px;" (click)="downloadFile()"></i></a>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div class="form-group fg-line" style="margin-bottom:1rem;">
          <label class="control-label">Motivo:</label>
          <textarea class="motivo-textarea" [readonly]="true">{{aulaAluno?.justificacoesFaltas?.motivo}}</textarea>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
        <div class="form-group fg-line" style="margin-bottom:1rem;">
          <label class="custom-control custom-checkbox">
            <input class="custom-control-input" type="checkbox" name="flagAprovado"
                   [ngModel]="aulaAluno?.justificacoesFaltas?.flagAprovado"
                   [disabled]="aulaAluno.justificacoesFaltas?.flagFechado"
                   (ngModelChange)="aulaAluno.justificacoesFaltas.flagAprovado = $event ? true : false; aulaAluno.justificacoesFaltas.flagRecusado = $event ? false : aulaAluno.justificacoesFaltas.flagRecusado; aulaAluno.aulaAssiduidade = 3">
            <span class="custom-control-indicator"></span>
            <span class="custom-control-description">Aprovar justificação</span>
          </label>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
        <div class="form-group fg-line" style="margin-bottom:1rem;">
          <label class="custom-control custom-checkbox">
            <input class="custom-control-input" type="checkbox" name="flagRecusado"
                   [ngModel]="aulaAluno?.justificacoesFaltas?.flagRecusado"
                   [disabled]="aulaAluno.justificacoesFaltas?.flagFechado"
                   (ngModelChange)="aulaAluno.justificacoesFaltas.flagRecusado = $event ? true : false; aulaAluno.justificacoesFaltas.flagAprovado = $event ? false : aulaAluno.justificacoesFaltas.flagAprovado; aulaAluno.aulaAssiduidade = 2" />
            <span class="custom-control-indicator"></span>
            <span class="custom-control-description">Não aprovar justificação</span>
          </label>
        </div>
      </div>
    </div>
    <div mat-dialog-content *ngIf="aulaAluno?.justificacoesFaltas?.dataAprovado != null || aulaAluno?.justificacoesFaltas?.dataRecusado != null" style="text-decoration: underline;font-size:10px;">
      <div class="row">
        <div class="col-4">
          <strong *ngIf="aulaAluno.justificacoesFaltas.flagAprovado && aulaAluno.justificacoesFaltas.dataAprovado != null">Data de Aprovação:</strong>
          <span style="margin-left:5px;" *ngIf="aulaAluno.justificacoesFaltas.flagAprovado">{{aulaAluno.justificacoesFaltas.dataAprovado | date: 'dd/MM/yyyy HH:mm'}}</span>
          <strong *ngIf="aulaAluno.justificacoesFaltas.flagRecusado && aulaAluno.justificacoesFaltas.dataRecusado != null">Data de Recusa:</strong>
          <span style="margin-left:5px;" *ngIf="aulaAluno.justificacoesFaltas.flagRecusado">{{aulaAluno.justificacoesFaltas.dataRecusado | date: 'dd/MM/yyyy HH:mm'}}</span>
        </div>
        <div class="col-4" *ngIf="aulaAluno.justificacoesFaltas.flagFechado">
          <strong *ngIf="aulaAluno.justificacoesFaltas.flagAprovado && aulaAluno.justificacoesFaltas.dataAprovado != null">Aprovado por:</strong>
          <strong *ngIf="aulaAluno.justificacoesFaltas.flagRecusado && aulaAluno.justificacoesFaltas.dataRecusado != null">Recusado por:</strong>
          <span *ngIf="(aulaAluno.justificacoesFaltas.idProfessor == 0 || aulaAluno.justificacoesFaltas.idProfessor == null)" style="margin-left:5px;">Secretaria</span>
          <span *ngIf="aulaAluno.justificacoesFaltas.flagDT && aulaAluno.justificacoesFaltas.idProfessor != null" style="margin-left:5px;">Diretor</span>
          <span *ngIf="!aulaAluno.justificacoesFaltas.flagDT && aulaAluno.justificacoesFaltas.idProfessor != null" style="margin-left:5px;">Professor</span>
        </div>
      </div>


    </div>
  </div>
</div>

<div class="modal-footer" style="padding-top: 0px">
  <button type="button" class="btn btn-outline-primary" (click)="verSumarios = true" *ngIf="!verSumarios">Ver Sumários</button>
  <button type="button" class="btn btn-outline-primary" (click)="!verSumarios = false" *ngIf="verSumarios">Esconder Sumários</button>
  <button type="button" class="btn btn-primary" (click)="save()">Guardar</button>
  <!--[disabled]="!isFormChanged()"-->
  <button type="button" class="btn btn-outline-primary" (click)="closeModal()">Fechar</button>
</div>
<!--</ng-template>-->
