<div class="container">
  <div class="card card-container" style="padding-bottom:0%;">
    <img id="profile-img" class="profile-img-card" src="./assets/img/logo_blue.svg" />
    <p id="profile-name" class="profile-name-card" style="color: #0094d4;font-weight: 500;">Área Reservada do Professor</p>
    <!-- Manutenção -->
    <div *ngIf="flagManutencao">
      <p id="profile-name" class="profile-name-card" style="margin-top: 5px;color:black;">De momento a página está em manutenção, por favor tente novamente mais tarde.</p>

      <img src="assets/img/manutencao.png" class="profile-img-card" style="width: 300px;" />

    </div>

    <!-- Manutenção -->
    <p id="profile-name" class="profile-name-card" style="margin-bottom: 20px;margin-top: 5px;" *ngIf="!flagManutencao">{{nomeEscola}}</p>

    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" *ngIf="(!recuperar && !ajuda) && !flagManutencao">
      <div class="form-group">
        <input type="text" formControlName="username" class="form-control" placeholder="NIF ou Nome de utilizador" autocomplete="off" />
      </div>
      <div class="form-group">
        <input type="password" formControlName="password" class="form-control" placeholder="Palavra-passe" autocomplete="off" passwordToggle required />
      </div>
      <alert style="text-align: center"></alert>
      <div class="form-group" style="margin-bottom: 1rem !important">
        <button class="btn btn-primary" style="width: 100%; cursor: pointer">Entrar</button>
      </div>
      <a href="#" class="forgot-password" (click)="recuperar = true">
        Recuperar palavra-passe
      </a>
      <a href="#" class="forgot-password" (click)="ajuda = true">
        Problemas com o início de sessão?
      </a>
    </form>

    <form [formGroup]="recuperarForm" *ngIf="recuperar && !passSent && !ajuda">
      <p style="text-align: left; font-weight: bold">
        Recuperar palavra-passe
      </p>
      <div class="form-group">
        <input type="text" formControlName="codProc" class="form-control" placeholder="NIF ou Nome de utilizador" autocomplete="off" required />
      </div>
      <div class="form-group">
        <input type="text" name="email" formControlName="email" class="form-control" placeholder="Email de contacto" autocomplete="off" required
               pattern="^\w+([\.-][\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" [ngClass]="{'is-invalid' : recuperarForm.get('email').invalid && recuperarForm.get('email').errors.pattern }" />
        <div *ngIf="recuperarForm.get('email').invalid && recuperarForm.get('email').errors.pattern" class="invalid-feedback" style="display: block">Endereço de email inválido.</div>
      </div>
      <p style="text-align: left">
        Serão enviadas instruções para o email de contacto definido na ficha do utilizador.
      </p>
      <alert style="text-align: center"></alert>

      <!-- Recaptcha Google -->
      <div style="margin-bottom: 10px;">
        <re-captcha (resolved)="resolved($event)"
                    formControlName="recaptcha"
                    siteKey="{{siteKeyCaptcha}}"></re-captcha>
      </div>

      <div class="form-group" style="margin-bottom: 1rem !important">
        <button class="btn btn-primary" type="submit" style="width: 100%; cursor: pointer; margin-bottom: 10px" (click)="email()" [disabled]="recuperarForm.invalid">Recuperar palavra-passe</button>
        <button class="btn btn-light" type="button" style="width: 100%; cursor: pointer" (click)="goBack()">Cancelar</button>
      </div>
    </form>

    <div *ngIf="passSent">
      <div class="check_mark">
        <div class="sa-icon sa-success animate">
          <span class="sa-line sa-tip animateSuccessTip"></span>
          <span class="sa-line sa-long animateSuccessLong"></span>
          <div class="sa-placeholder"></div>
          <div class="sa-fix"></div>
        </div>
      </div>
      <p style="text-align: center">As instruções de recuperação foram enviadas com sucesso para o email de contacto definido na ficha do utilizador.</p>
      <div class="form-group" style="margin-bottom: 1rem !important; margin-top: 1rem !important">
        <button class="btn btn-primary" type="button" style="width: 100%; cursor: pointer" (click)="goBack()">Voltar ao início</button>
      </div>
    </div>

    <form *ngIf="ajuda">
      <p style="text-align: left; font-weight: bold">
        Problemas com o início de sessão?
      </p>
      <p style="text-align: left">
        1. Verifique o estado da sua ligação à internet;
      </p>
      <p style="text-align: left">
        2. Confirme que introduziu todos os dados de acesso correctamente;
      </p>
      <p style="text-align: left">
        3. Limpe os dados de navegação do browser (histórico/cache);
      </p>
      <p style="text-align: left">
        4. Confirme que o browser está actualizado (pode descarregar a versão mais recente abaixo)
      </p>
      <ul>
        <li><a href="https://www.google.com/intl/pt-PT/chrome/" target="_blank">Google Chrome</a></li>
        <li><a href="http://www.mozilla.org/pt-PT/firefox/new/" target="_blank">Mozilla Firefox</a></li>
        <li><a href="https://www.opera.com/pt" target="_blank">Opera</a></li>
      </ul>
      <p style="text-align: left">
        Se o problema persistir, contacte os serviços administrativos da escola.
      </p>

      <alert style="text-align: center"></alert>
      <div class="form-group" style="margin-bottom: 1rem !important">
        <button class="btn btn-primary" type="button" style="width: 100%; cursor: pointer" (click)="goBack()">Voltar</button>
      </div>
    </form>
    <div style="padding-top:20%;" *ngIf="!flagManutencao">
      <p id="profile-name" class="profile-name-card" style="font-weight: 400; font-size: 10px; margin-bottom:0%;">Desenvolvido por:</p>
      <img id="profile-img" class="profile-img-card" style="width: 75%; margin-bottom: 5%;" src="./assets/img/amcLogo.png" />
    </div>
    <!-- Manutenção -->
    <div *ngIf="flagManutencao">
      <p id="profile-name" class="profile-name-card" style="font-weight: 400; font-size: 10px; margin-bottom:0%;">Desenvolvido por:</p>
      <img id="profile-img" class="profile-img-card" style="width: 75%; margin-bottom: 5%;" src="./assets/img/amcLogo.png" />
    </div>
    <!-- Manutenção -->
  </div>
</div>
