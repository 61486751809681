import { Component, OnInit, ApplicationRef, OnDestroy } from '@angular/core';
import { SharedService } from "./shared/services/shared.service";
import { SwPush, SwUpdate } from '@angular/service-worker';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from "@angular/router"
import { UpdateService } from './shared/services/update.service';
import { HttpClient } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { interval, concat, Subscription } from 'rxjs';
import { InstallPromptComponent } from './pages/install-prompt/install-prompt.component';
import { MatDialog } from '@angular/material/dialog';
import { Platform } from '@angular/cdk/platform';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})

export class AppComponent implements OnInit, OnDestroy{
  private subscription: Subscription[] = [];
  title = 'appProfessores';
  private version;
  btnToInstalled;
  onLoading: Boolean = true;
  btnToInstalledIos;
  isIos = false;
  constructor(
    private router: Router,
    private sharedService: SharedService,
    private swPush: SwPush,
    private swUpdate: SwUpdate,
    private updateService: UpdateService,
    private http: HttpClient,
    public appRef: ApplicationRef,
    private dialog: MatDialog,
    private platform: Platform
  ) {   

    this.updateService.checkForUpdates();  

    router.events.subscribe((event: Event) => {

      if (event instanceof NavigationStart) {
        sessionStorage.setItem('pagina-anterior', sessionStorage.getItem('pagina-atual'));
        sessionStorage.setItem('pagina-atual', event.url);
      }
    });
  }

  ngOnInit() {
    // detect if the device is on iOS
    this.isIos = this.platform.IOS;

    if (this.swUpdate.isEnabled) {
      //verifica de 1m em 1m se existe uma nova versão => reforço ao ssrvice Worker que eventualmente poderá falhar
      const appIsStable$ = this.appRef.isStable.pipe(first(isStable => isStable === true));
      const everyTwoMinHours$ = interval(2000 * 60);
      const everyTwoMinOnceAppIsStable$ = concat(appIsStable$, everyTwoMinHours$);

      everyTwoMinOnceAppIsStable$.subscribe(() => this.update());

      //IOS
      if (this.isIos) {
        const isInStandaloneMode = ('standalone' in window.navigator) && (window.navigator['standalone']);
        if (!isInStandaloneMode) {
          this.sharedService.appNotInstalledCacheIOS()
            .then(() => {
              //PASSA O BOTÃO PARA TRUE
              //this.sharedService.updatebtnInstalledIOSVariable(true)
              //vai buscar o valor do botão
              this.sharedService.btnToInstalledIOS$.subscribe((value) => {
                this.btnToInstalledIos = value;
              })
            })
        }
        //ANDROID
      } else {
        this.sharedService.btnToInstalled$.subscribe((value) => {
          this.btnToInstalled = value;
          window.addEventListener('beforeinstallprompt', (e) => {
            this.sharedService.updatebtnInstalledVariable(true);
            e.preventDefault();
            this.sharedService.setBeforeInstallPrompt(e);
          });
          //Se não estiver instalada abre o pop-up
          if (this.btnToInstalled) {
            this.sharedService.getAppInstalledCacheValue().then((value) => {
              if (!isNaN(parseInt(value, 10))) {
                var count = parseInt(value, 10)
                if (count > 2) {
                  const dialogRef = this.dialog.open(InstallPromptComponent, { data: { mobileType: this.isIos ? 'ios' : 'android' } });
                  dialogRef.afterClosed().subscribe(result => {
                    if (result) {
                      this.sharedService.resetAppInstalledCache()
                    } else {
                      this.sharedService.resetAppInstalledCache()
                    }
                  });
                }
              }
            })
          }
        });
      }
    }

    
    ////if (this.swUpdate.isEnabled) {

    ////  //const appIsStable$ = this.appRef.isStable.pipe(first(isStable => isStable === true));
    ////  //const everyTwoMinHours$ = interval(2000 * 60);
    ////  //const everyTwoMinOnceAppIsStable$ = concat(appIsStable$, everyTwoMinHours$);

    ////  //everyTwoMinOnceAppIsStable$.subscribe(() => this.update());


    ////  //ANTES 10/11/2023 https://app.clickup.com/t/86933gjzf
    ////  //verifica de 1m em 1m se existe uma nova versão => reforço ao ssrvice Worker que eventualmente poderá falhar
    ////  const appIsStable$ = this.appRef.isStable.pipe(first(isStable => isStable === true));
    ////  const everyTwoMinHours$ = interval(2000 * 60);
    ////  const everyTwoMinOnceAppIsStable$ = concat(appIsStable$, everyTwoMinHours$);

    ////  everyTwoMinOnceAppIsStable$.subscribe(() => this.update());

    ////  window.addEventListener('beforeinstallprompt', (e) => {
    ////    e.preventDefault();
    ////    this.sharedService.setInstallPrompt(e);
    ////     //instalar com trigger de botão
    ////    //this.sharedService.setPromp(e);
    ////  });
    ////}
  }
  //PARA DEBUGGING DO DIALOG
  //openDialog() {
  //  const dialogRef = this.dialog.open(InstallPromptComponent, { data: { mobileType: this.isIos ? 'ios' : 'android' } });
  //  dialogRef.afterClosed().subscribe(result => {
  //    if (result) {
  //      this.sharedService.resetAppInstalledCache()
  //    } else {
  //      this.sharedService.resetAppInstalledCache()
  //    }
  //  });
  //}
  //IOS
  installPwaIOS() {
    const dialogRef = this.dialog.open(InstallPromptComponent, { data: { mobileType: this.isIos ? 'ios' : 'android' } });
    dialogRef.afterClosed().subscribe(result => {
      this.sharedService.changeAppInstalledCacheIOS("false")
      this.sharedService.updatebtnInstalledIOSVariable(false)
    });
  }

  instalarApp() {
    this.sharedService.setInstallPrompt();
  }

  private update() {
    this.subscription.push(
      this.http.get("assets/version.txt").subscribe(valor => {
        this.version = valor;

        if (localStorage.getItem("version") == null && this.version != null && this.version != "") localStorage.setItem("version", this.version);

        console.log("versão " + this.version);
        console.log("versão local" + localStorage.getItem("version"));

        if (this.version != null && this.version != "" && Number.isInteger(this.version) && this.version > localStorage.getItem("version")) {
          console.log("this version " + this.version)
          localStorage.setItem("version", this.version);
          window.location.reload();
        }
      },
        (err) => {
          this.version = 0;
          localStorage.setItem("version", this.version);
        },
      )
    );
  }

  ngOnDestroy() {
    if (this.subscription != null) this.subscription.forEach(subscription => subscription.unsubscribe());
  }
}
