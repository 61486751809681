<router-outlet></router-outlet>
<div class="page-loader page-loader--inner">
  <div class="page-loader__spinner">
    <svg viewBox="25 25 50 50">
      <circle cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10" />
    </svg>
  </div>
</div>
<!-- PARA DEBUGGING DO DIALOG -->
<!--<div style="position: fixed; bottom: 40px; right: 40px; z-index: 7;" >
  <button class="btn btn-primary" (click)="openDialog()" style="border-radius: 5px; text-transform:none;"><i class="zmdi zmdi-download"></i>&nbsp;&nbsp; Open Dialog</button>
</div>-->


<div style="position: fixed; bottom: 20px; right: 20px; z-index: 7;" *ngIf="btnToInstalled">
  <button class="btn btn-primary" (click)="instalarApp()" style="border-radius: 5px; text-transform:none;"><i class="zmdi zmdi-download"></i>&nbsp;&nbsp; Instalar</button>
</div>
<div style="position: fixed; bottom: 20px; right: 20px; z-index: 7;" *ngIf="btnToInstalledIos">
  <button class="btn btn-primary" (click)="installPwaIOS()" style="border-radius: 5px; text-transform:none;"><i class="zmdi zmdi-download"></i>&nbsp;&nbsp; Instalar</button>
</div>


<!--<cookie-law name="MusaProfessoresCookies" expiration="200">
  <i class="fas fa-cookie-bite fa-fw"></i><strong style="font-size: 14px"> Utilização de cookies: </strong><span style="font-size: 12px">
    Ao continuar a sua navegação está a consentir a utilização de cookies que melhoram a sua
    experiência de utilizador.
  </span>
</cookie-law>-->

<!--<go-top-button [animate]="true" [speed]="50" [acceleration]="2" [scrollDistance]="150" [styles]="{'color': 'white',
               'background-color' : '#5c7d9c',
               'border-radius' : '50px',
               'height': '50px',
               'width': '50px',
               'top': 'auto',
               'bottom': '20px',
               'left': 'auto',
               'right' : '20px',
               'z-index': '100'
               }">
  <i class="fas fa-angle-up fa-fw"></i>
</go-top-button>-->

