import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { default as jwt_decode } from 'jwt-decode';

import { environment } from '../../../environments/environment';
import { User } from '../models/user';

@Injectable({ providedIn: 'root' })
export class UserService {
  private user: User;
  private token: string;

  private userParsed() {
    let parsed;

    try {
      parsed = JSON.parse(sessionStorage.getItem('currentUser'));
    } catch (error) { }

    return parsed;
  }

  hasUser() {
    return !!this.user;
  }

  hasToken() {
    const { token = null } = this.userParsed() || {};
    return !!token;
  }

  getUserToken() {
    const { token = null } = this.userParsed() || {};
    return token;
  }

  getUserPersist() {
    return this.userParsed();
  }

  setUser(user: User) {
    this.user = jwt_decode(user.token);
    this.token = user.token;
  }

  getUser() {
    return this.user;
  }

  getUserUid() {
    return this.user.unique_name;
  }

  getUserSigla() {
    return this.user.sigla;
  }

  getUserName() {
    return this.user.username;
  }

  getNome() {
    return this.user.nameUser;
  }

  getUserFoto() {
    return this.user.foto;
  }

  resetUser() {
    this.user = null;
  }
}
